import React, { useContext } from 'react'
import cn from 'classnames'

import { PrimaryButton } from '../widgets/Button.js'
import Modal from '../widgets/Modal.js'
import { track_knn_events } from '../../utils/tracking_utils.js'
import { USER_SAVE_KNN_SEARCHES } from '../../model/user_settings.js'
import { UserSettingsDispatchContext } from '../UserSettingsContext.js'
import { CONTEXT } from '../../utils/knn_search.js'

import s from './WelcomeModal.module.scss'

const WelcomeModal = () => {
  const { save_user_settings_handler } = useContext(UserSettingsDispatchContext)
  function change_save_knn_searches(save_knn_searches) {
    track_knn_events(`context="${CONTEXT}" action="change_privacy_mode" value="${save_knn_searches}"`)
    save_user_settings_handler(USER_SAVE_KNN_SEARCHES, save_knn_searches)
  }

  return (
    <Modal
      title={'Save search history?'}
      backdrop={true}
      footer={
        <PrimaryButton outline onClick={() => change_save_knn_searches(false)}>Don’t save search history</PrimaryButton>
      }
      primary_button={(
        <PrimaryButton onClick={() => change_save_knn_searches(true)}>Save search history</PrimaryButton>
      )}
      zIndex={10001}
      centered={true}
      className={cn('fs-unmask', s.block)}
    >
      <div>By default, your search history is disabled. When enabled, your search history lets you review your searches under "Pinned and recent searches".</div>
    </Modal>
  )
}

export default WelcomeModal