import React, { useCallback, useContext, useEffect, useState } from 'react'
import cn from 'classnames'
import _ from 'underscore'
import qs from 'query-string'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import { fetch_families_for_tag_group, fetch_families_for_tag_value } from '../family_tagging/family_tag_utils.ts'
import Modal, { ScrollModal } from '../widgets/Modal.js'
import PortfolioSearchNavigation from './PortfolioSearchNavigation.js'
import OrgSearch from '../orgs/OrgSearch.js'
import OrgSearchMultiple from '../orgs/OrgSearchMultiple.js'
import ReportBuilderBasket from './ReportBuilderBasket.js'
import FamilyTagSectionsDisplay from '../family_tagging/FamilyTagSectionsDisplay.tsx'
import PatentFamilyGlobalSearch from '../patent_family_list/PatentFamilyGlobalSearch.js'
import ErrorModal from '../ErrorModal.js'
import ErrorBody from '../ErrorBody'
import Spinner from '../widgets/Spinner.js'
import ContainerFullWidth from '../ContainerFullWidth.js'
import { withUser } from '../UserContext.js'
import OrgGroup from '../orgs/OrgGroup.js'
import ReportBasketContext from './ReportBasketContext.js'
import {
  get_family_tag_as_portfolio_item,
  get_patent_upload_as_portfolio_item,
  get_boolean_search_as_portfolio_item,
  get_org_as_portfolio_item,
  get_org_group_as_portfolio_item,
  is_patent_families_type,
  is_family_tag_type,
  get_all_groupable_idxs,
  is_all_group_by_selected
} from '../../model/portfolio_basket.js'
import {
  AVAILABLE_SCORE_THRESHOLDS,
  get_selected_classifiers_from_classifier_sources,
  get_technology_partitioning_by_utt,
  is_report_with_classifiers,
  is_report_with_clustered_techs,
  is_report_with_multiclass,
  TECH_PARTITIONING_TYPE_CLASSIFIER,
  TECH_PARTITIONING_TYPE_CLUSTERING,
  TECH_PARTITIONING_TYPE_UTT
} from '../../model/technology_basket.js'
import { EDIT_CLASSIFIER_SELECTION_LINK_TEXT, SELECT_CLASSIFIERS_LINK_TEXT } from '../../constants/technology_basket.js'
import {
  is_creator,
  has_cipher_product,
  is_poweruser, is_aistemos,
} from '../../utils/user_permissions.js'
import {
  fetch_input_by_id,
  PORTFOLIO_SEARCH_TYPE_BY_ID,
  build_report,
  prepare_base_report_portfolios,
  get_portfolio_basket_orgs_total_size,
  save_new_report_with_existing_internal_id,
  get_preselected_technology_basket_for_eval_classifier,
  get_subpath_for_report_link,
  REPORT_BUILDER_MODE_ID_PORTFOLIO,
  REPORT_BUILDER_MODE_ID_OPTIONS,
  REPORT_BUILDER_MODE_ID_TECHNOLOGY,
  PORTFOLIO_SIZE_NO_PASS_THRESHOLD,
  get_basket_portfolio_total_size,
  PORTFOLIO_SIZE_WARNING_THRESHOLD,
  get_version_appropriate_existing_reports,
  get_classifier_path,
  PORTFOLIO_SEARCH_TYPE_LANDSCAPE_SEARCH_ID,
  AISTEMOS_CLASSIFIER_LANDSCAPE_LIMIT,
  CLASSIFIER_LANDSCAPE_LIMIT,
  get_default_report_name,
  get_report_type_from_input,
  PORTFOLIO_SEARCH_TYPE_ORG_SEARCH_ID,
  get_default_portfolio_search_mode,
  build_technology_basket_from_product,
  ALL_FAMILIES_LANDSCAPE_OPTION_ID,
  ID_TO_LANDSCAPE_GROUP,
} from '../../utils/report_builder_utils.js'
import { fetch_report_input } from '../../utils/report_reader_utils.js'
import { get_leaf_nodes_as_array } from '../../utils/classifier_tree_utils.js'
import { fetch_company_lists } from '../../utils/company_list_utils.js'
import { are_same_orgs, is_org_group_type, is_org_type } from '../../utils/organisation_utils.js'
import { get_normalised_search_phrase } from '../../utils/patent_family_list_utils.js'
import { fetch_report_metadata } from '../../utils/report_created_utils.js'
import { track_report_builder_event } from '../../utils/tracking_utils.js'
import { is_not_found } from '../../utils/axios_utils.js'
import { get_classifier_groups, UNCATEGORISED_GROUP_NAME, USER_OTHER_ID } from '../../utils/classifier_group_utils.js'
import { PRODUCT_GOOGLE, PRODUCT_ND } from '../../constants/cipher_product_ids.js'
import { REPORT } from '../../constants/paths.js'
import { get_default_utt_version, should_include_unrelated_technologies } from '../../utils/user_settings_utils.js'
import { get_as_map, pluralise_text, sum } from '../../utils/utils.js'
import { check_for_existing_and_in_progress_reports } from '../../utils/choreo_utils.js'
import {
  build_classifier_landscape_report_input,
  build_generic_builder_report_input,
  build_utt_landscape_report_input
} from '../../utils/report_input_utils.js'
import { filter_matched_patents } from '../patent_upload/table/matched_patents_table_utils.js'
import { send_error_to_sentry } from '../../utils/sentry_utils.js'
import { UserSettingsContext } from '../UserSettingsContext.js'
import InvalidOrganisationsWarning from '../orgs/InvalidOrganisationsWarning.js'
import UnavailableClassifiersWarning from '../classifiers/UnavailableClassifiersWarning.js'
import {
  get_negatives_processing,
  NEGATIVES_PROCESSING_EXCLUDE,
  NEGATIVES_PROCESSING_INCLUDE,
  NEGATIVES_PROCESSING_UTT
} from '../../model/negatives_processing.js'
import { save_eval_report_selected_charts_in_state } from '../../utils/report_state_utils.js'
import ReportBuilderNoAccessPanel from './ReportBuilderNoAccessPanel.js'
import { get_list_of_hashed_classifier_ids } from '../../utils/tracking_hashed_id_utils.js'
import ProgressPrompt from './ProgressPrompt.js'
import TechnologiesDisplay from './TechnologiesDisplay.js'
import ReportNameInput from '../ReportNameInput.js'
import { is_valid_report_name, normalise_name, remove_not_allowed_chars_from_text } from '../../utils/name_utils.js'
import MultiLabelOptions from './MultiLabelOptions.js'
import NegativesProcessingOptions from './NegativesProcessingOptions.js'
import ScoreThresholdControl from './ScoreThesholdControl.js'
import { CLASSIFIER_SCORE_THRESHOLD_DEFAULT } from '../../constants/report_input.js'
import PortfolioRollupLimitControl from './PortfolioRollupLimitControl.js'
import OwnerLevelControl from './OwnerLevelControl.js'
import {
  ReportBuilderBasketInfo,
  ReportBuilderBasketStopMessage,
  ReportBuilderBasketWarning
} from './ReportBuilderBasketWarning.js'
import { get_data_version } from '../../utils/domain_utils.js'
import Stepper from './Stepper.js'
import { DEFAULT_PORTFOLIO_ROLLUP_LIMIT } from '../../model/portfolio_rollup_limits.js'
import { DEFAULT_GROUP_BY_OWNER_LEVEL } from '../../model/group_by_owner_level.js'
import { ProgressBanner } from '../viewer/ReportInProgressBanner.js'
import PortfolioCheckboxList from './PortfolioCheckboxList.js'
import { S3_ALL_FAMILIES_PORTFOLIO } from '../../model/portfolios.js'
import { get_utt_classes } from '../../utils/static_data_utils.js'
import { transform_classes_to_classifier_tree } from '../../utils/utt_utils.js'
import { CLASSIFIER_LANDSCAPE_REPORT_TYPE, UTT_LANDSCAPE_REPORT_TYPE } from '../../constants/constants.js'
import LandscapeAsPortfolio from './LandscapeAsPortfolio.js'
import TechnologySelector from './TechnologySelector.js'
import { get_accession_date_portfolio_item } from './wizard/builder_wizard_utils.js'
import PatentUpload from '../patent_upload/PatentUpload.js'

import cs from '../cipher_styles.module.scss'
import list_view_styles from './builder_list_view_styles.module.scss'
import s from './ReportBuilder.module.scss'

const ReportBuilder = (
  {
    user,
    history,
    location,
    is_nd_report,
    is_valuation_report,
  }) => {

  const query_params = qs.parse(location.search)

  const { user_settings } = useContext(UserSettingsContext)
  const default_utt_version = get_default_utt_version(user_settings)
  const classifiers_landscape_limit = is_aistemos(user) ? AISTEMOS_CLASSIFIER_LANDSCAPE_LIMIT : CLASSIFIER_LANDSCAPE_LIMIT

  const [is_fetching_initial_data, set_is_fetching_initial_data] = useState(true)
  const [is_fetching_org_set, set_is_fetching_org_set] = useState(false)
  const [show_spinner, set_show_spinner] = useState(false)
  const [classifier_groups, set_classifier_groups] = useState([])
  const [utt_classes_group, set_utt_classes_group] = useState(null)
  const [utt_version, ] = useState(default_utt_version)

  const [mode, set_mode] = useState(REPORT_BUILDER_MODE_ID_PORTFOLIO)
  const [portfolio_search_mode, set_portfolio_search_mode] = useState(get_default_portfolio_search_mode({portfolio_search_mode: query_params.portfolio_search_mode, is_valuation_report, is_nd_report}))
  const [is_multiple_org_search, set_is_multiple_org_search] = useState(query_params.search_multiple || false)
  const [selected_classifier_group_id, set_selected_classifier_group_id] = useState(null)

  const [report_name, set_report_name] = useState(null)
  const [portfolios, set_portfolios] = useState(null)
  const [classifiers, set_classifiers] = useState(null)
  const [technology_partitioning, set_technology_partitioning] = useState(null)
  const [portfolio_roll_up_limit, set_portfolio_roll_up_limit] = useState(DEFAULT_PORTFOLIO_ROLLUP_LIMIT)
  const [group_by_owner_level, set_group_by_owner_level] = useState(DEFAULT_GROUP_BY_OWNER_LEVEL)
  const [portfolios_to_cluster, set_portfolios_to_cluster] = useState(null)
  const [negatives_processing, set_negatives_processing] = useState(null)
  const [multi_label, set_multi_label] = useState(null)
  const [score_threshold, set_score_threshold] = useState(null)

  const [org_group, set_org_group] = useState([])
  const [orgs_for_suggestions, set_orgs_for_suggestions] = useState(null)
  const [families_for_suggestions, set_families_for_suggestions] = useState(null)
  const [portfolio_basket_sizes, set_portfolio_basket_sizes] = useState([])
  const [is_calculate_orgs_total_size, set_is_calculate_orgs_total_size] = useState(false)
  const [portfolio_basket_orgs_total_size, set_portfolio_basket_orgs_total_size] = useState(0)
  const [should_check_existing_reports, set_should_check_existing_reports] = useState(false)
  const [existing_report, set_existing_report] = useState(null)
  const [in_progress_report, set_in_progress_report] = useState(null)

  const [invalid_portfolios, set_invalid_portfolios] = useState(null)
  const [unavailable_classifiers, set_unavailable_classifiers] = useState(null)
  const [user_company_lists, set_user_company_lists] = useState(null)
  const [invalid_tagged_families, set_invalid_tagged_families] = useState(null)
  const [start_report, set_start_report] = useState(false)
  const [evaluation_classifier_id, set_evaluation_classifier_id] = useState(null)
  const [selected_landscape_option, set_selected_landscape_option] = useState(null)
  const [selected_new_families_option, set_selected_new_families_option] = useState(null)

  const [custom_upload_job_id,] = useState(query_params.job_id)
  const [input_id,] = useState(query_params.input || null)
  const [knn_search_input,] = useState(query_params.knn_search_input_id || null)
  const [base_report_id,] = useState(query_params.base_report || null)
  const [alert_id,] = useState(query_params.alert || null)

  const [fetch_base_report_input, set_fetch_base_report_input] = useState(false)
  const [loading_base_report, set_loading_base_report] = useState(false)

  const [error_fetching_initial_data, set_error_fetching_initial_data] = useState(null)
  const [build_report_error, set_build_report_error] = useState(null)
  const [loading_base_report_error, set_loading_base_report_error] = useState(null)
  const [loading_clickthrough_input_error, set_loading_clickthrough_input_error] = useState(null)
  const [verify_base_report_portfolios_error, set_verify_base_report_portfolios_error] = useState(null)
  const [fetch_user_company_lists_error, set_fetch_user_company_lists_error] = useState(null)
  const [duplicated_basket_item_error, set_duplicated_basket_item_error] = useState(false)
  const [existing_reports_fetch_error, set_existing_reports_fetch_error] = useState(null)

  const is_org_group = org_group && org_group.length > 0
  const has_classifiers = (classifier_groups.length > 0)
  const portfolio_size = get_basket_portfolio_total_size(portfolios, portfolio_basket_sizes, portfolio_basket_orgs_total_size)
  const is_report_big = portfolio_size > PORTFOLIO_SIZE_WARNING_THRESHOLD
  const is_report_too_big = portfolio_size > PORTFOLIO_SIZE_NO_PASS_THRESHOLD // Only limit non-classifier reports (classifier reports are truncated, so are safe to run)

  useEffect(() => {
    document.title = 'Classification: Custom report builder'

    Promise.all([
      fetch_company_lists(),
      get_classifier_groups(user, true /* include nd */),
      get_utt_classes(utt_version)
    ])
      .catch(err => {
        set_error_fetching_initial_data(err)
        set_is_fetching_initial_data(false)
        throw err
      })
      .then(([company_lists, classifier_groups, utt]) => {
        set_classifier_groups(classifier_groups)
        set_utt_classes_group(utt != null ? transform_classes_to_classifier_tree(utt) : null)
        set_user_company_lists(company_lists)
        set_is_fetching_initial_data(false)

        const id_to_classifier_group = get_as_map(classifier_groups, 'id')

        if (is_nd_report) {
          const nd_classifiers = id_to_classifier_group[PRODUCT_ND]
          set_selected_classifier_group_id(PRODUCT_ND)
          set_classifiers(build_technology_basket_from_product(nd_classifiers))
        }

        if (is_valuation_report) {
          const google_classifiers = id_to_classifier_group[PRODUCT_GOOGLE]
          set_selected_classifier_group_id(PRODUCT_GOOGLE)
          set_classifiers(build_technology_basket_from_product(google_classifiers))
        }

        if (base_report_id) {
          set_fetch_base_report_input(true)
          return
        }

        if (input_id || knn_search_input) {
          set_show_spinner(true)
          fetch_input_by_id({input_id, knn_search_input}) //could be input from click through or wizard
            .then(report_input => {
              const {portfolios, portfolio_sizes, organisations_total_size, invalid_portfolios, report_name, technology_partitioning, evaluation_classifier_id } = report_input || {}

              const {classifier_sources, multi_label, negatives_processing, portfolios_to_cluster, type, use_superclasses} = technology_partitioning || {}

              set_report_name(report_name)
              set_portfolios(portfolios)
              set_portfolio_basket_sizes(portfolio_sizes)
              set_evaluation_classifier_id(evaluation_classifier_id)
              set_portfolio_basket_orgs_total_size(organisations_total_size)
              set_invalid_portfolios(invalid_portfolios)

              if (!_.isEmpty(portfolios || [])) {
                set_selected_landscape_option(null)
              }

              if (evaluation_classifier_id) {
                const classifier_basket = get_preselected_technology_basket_for_eval_classifier(evaluation_classifier_id, classifier_groups)
                const eval_classifier = classifier_basket[0]
                if (eval_classifier) {
                  const {path=[]} = eval_classifier
                  set_technology_partitioning({type: TECH_PARTITIONING_TYPE_CLASSIFIER})
                  set_selected_classifier_group_id(path[0] || USER_OTHER_ID)
                  set_negatives_processing(get_negatives_processing(NEGATIVES_PROCESSING_INCLUDE))
                }
                set_classifiers(classifier_basket)
              } else {
                set_classifiers(get_selected_classifiers_from_classifier_sources(classifier_sources))
              }

              if (multi_label != null) {
                set_multi_label(multi_label)
              }

              if (negatives_processing != null) {
                set_negatives_processing(negatives_processing)
              }

              set_portfolios_to_cluster(portfolios_to_cluster)

              if (type) {
                set_technology_partitioning(TECH_PARTITIONING_TYPE_UTT === type ? get_technology_partitioning_by_utt({
                  use_utt_superclasses: use_superclasses,
                  utt_version
                }) : { type })
              }

              if (evaluation_classifier_id && _.isEmpty(portfolios || [])) {
                // custom eval report; go to org search
                set_mode(REPORT_BUILDER_MODE_ID_PORTFOLIO)
                set_portfolio_search_mode(PORTFOLIO_SEARCH_TYPE_BY_ID.org_search)
              } else {
                set_mode(type ? REPORT_BUILDER_MODE_ID_OPTIONS : REPORT_BUILDER_MODE_ID_TECHNOLOGY)
              }

              set_show_spinner(false)
            })
            .catch(error => {
              set_loading_clickthrough_input_error(error)
              set_show_spinner(false)
            })
        }
      })
  }, [base_report_id, input_id, knn_search_input, is_nd_report, is_valuation_report, utt_version, user])

  useEffect(() => {
    if ((PORTFOLIO_SEARCH_TYPE_LANDSCAPE_SEARCH_ID === portfolio_search_mode.id) && (!selected_landscape_option)) {
      set_selected_landscape_option(ID_TO_LANDSCAPE_GROUP[ALL_FAMILIES_LANDSCAPE_OPTION_ID])
    }
  }, [portfolio_search_mode, selected_landscape_option])

  const get_all_classifiers = useCallback(() => {
    // Get a flat list of all classifiers (regardless of level in a taxonomy)
    const classifiers_leaves = classifier_groups.map(classifier_group => get_leaf_nodes_as_array(classifier_group))
    return [].concat(...classifiers_leaves)
  }, [classifier_groups])

  const prepare_base_report_basket = useCallback((report_input) => {

    const {
      portfolios = [],
      technology_partitioning,
      technologyPartitioning,
      portfolio_roll_up_limit,
      portfolio_group_by_owner_ancestor_type,
    } = report_input

    set_portfolio_roll_up_limit(portfolio_roll_up_limit)
    set_group_by_owner_level(portfolio_group_by_owner_ancestor_type)

    const tech_partitioning = technology_partitioning || technologyPartitioning
    const available_classifiers = get_all_classifiers()

    const {
      type: tech_partitioning_type,
      classifier_sources,
      include_negatives,
      negatives_processing,
      multi_label,
      threshold,
      portfolios_to_cluster
    } = tech_partitioning

    const report_with_classifiers = is_report_with_classifiers({tech_partitioning_type})
    let selected_classifiers = null

    if (report_with_classifiers) {
      selected_classifiers = _.reduce(classifier_sources, (selected, classifier_from_source) => {
        // match this classifier source from classifiers available to this user (personal or taxonomy)
        const match_from_available = _.find(available_classifiers, available_classifier => {
          if (available_classifier.classifier_id !== classifier_from_source.id) {
            return false
          }
          const available_classifier_path = get_classifier_path(available_classifier)
          // only match with classifiers from the same tree
          return available_classifier_path[0] === (classifier_from_source.path || [])[0] || UNCATEGORISED_GROUP_NAME
        })
        if (!match_from_available) {
          return selected
        }
        const path = get_classifier_path(match_from_available)
        // use latest path, name, description... at some point we may want to handle version differently/ make it explicit
        const {name, description, classifier_id, version} = match_from_available
        return selected.concat([{name, description, classifier_id, version, path}])
      }, [])

      const unavailable = classifier_sources.filter(classifier => {
        return !_.any(selected_classifiers, selected_classifier => selected_classifier.classifier_id === classifier.id)
      })

      set_classifiers(selected_classifiers)
      set_unavailable_classifiers(unavailable)

      const updated_negatives_processing = is_report_big ? NEGATIVES_PROCESSING_EXCLUDE : (
        include_negatives != null ? (include_negatives ? NEGATIVES_PROCESSING_UTT : NEGATIVES_PROCESSING_EXCLUDE) : negatives_processing
      )

      set_negatives_processing(updated_negatives_processing)
      set_multi_label(multi_label)
      set_score_threshold(threshold)
      set_technology_partitioning({type: TECH_PARTITIONING_TYPE_CLASSIFIER})
    }

    if (is_report_with_clustered_techs({tech_partitioning_type})) {
      set_portfolios_to_cluster(portfolios_to_cluster)
      set_technology_partitioning({type: TECH_PARTITIONING_TYPE_CLUSTERING})
    }

    if (is_report_with_multiclass({tech_partitioning_type: tech_partitioning.type})) {
      const {use_superclasses: use_utt_superclasses} = tech_partitioning || {}

      set_technology_partitioning(get_technology_partitioning_by_utt({use_utt_superclasses, utt_version}))
    }

    set_selected_landscape_option(null)
    set_portfolio_search_mode(PORTFOLIO_SEARCH_TYPE_BY_ID.org_search)

    prepare_base_report_portfolios({portfolios})
      .then(data => {
        const {portfolios, invalid_portfolios, organisations_total_size, portfolio_sizes} = data
        set_portfolios(portfolios)
        set_portfolio_basket_sizes(portfolio_sizes)
        set_invalid_portfolios(invalid_portfolios)
        set_portfolio_basket_orgs_total_size(organisations_total_size)
        if ((portfolios || []).length > 0 && (!report_with_classifiers || (report_with_classifiers && (selected_classifiers || []).length > 0))) {
          set_should_check_existing_reports(true)
          set_mode(REPORT_BUILDER_MODE_ID_OPTIONS)
          set_start_report(query_params.start)
        }
        set_loading_base_report(false)
      })
      .catch(error => {
        set_verify_base_report_portfolios_error(error)
        set_loading_base_report(false)
      })
  }, [utt_version, get_all_classifiers, query_params, is_report_big])

  useEffect(() => {
    if (!fetch_base_report_input || !base_report_id) return

    track_report_builder_event('action="preload_report_parameters"')
    set_loading_base_report(true)
    set_fetch_base_report_input(false)
    fetch_report_metadata(base_report_id)
      .then(({internal_report_id, title, evaluation_classifier_id}) => {
        set_report_name(title)
        set_evaluation_classifier_id(evaluation_classifier_id)
        return fetch_report_input(internal_report_id)
      })
      .catch(error => {
        set_loading_base_report_error(error)
        set_loading_base_report(false)
        throw error
      })
      .then(report_input => {
        prepare_base_report_basket(report_input) // this is not a Promise, but actually an async call which sets state (handles its own errors etc...)
      })

  }, [fetch_base_report_input, prepare_base_report_basket, base_report_id])

  const build_report_input = useCallback(() => {
    const {use_superclasses} = technology_partitioning || {}
    const report_type = get_report_type_from_input({portfolios, technology_partitioning, is_nd_report, selected_landscape_option, selected_new_families_option})

    const report_name = get_default_report_name({portfolios, classifiers, technology_partitioning, selected_landscape_option, selected_new_families_option, is_nd_report})

    const clean_portfolios = (portfolios || []).map(item => _.omit(item, ['original_name', 'static_name', 'is_landscape']))

    if (CLASSIFIER_LANDSCAPE_REPORT_TYPE === report_type) {
      return build_classifier_landscape_report_input({
        report_name,
        portfolios: clean_portfolios,
        classifiers,
        group_by_owner_level,
        multi_label,
        evaluation_classifier_id,
        portfolio_roll_up_limit,
        threshold: score_threshold
      })
    }

    if (UTT_LANDSCAPE_REPORT_TYPE === report_type) {
      const selected_classifier = classifiers[0] || {}
      const {classifier_id} = selected_classifier

      return build_utt_landscape_report_input({report_name, classifier: {...selected_classifier, id: classifier_id}, group_by_owner_level, portfolio_roll_up_limit, utt_version})
    }

    return build_generic_builder_report_input({
      report_name,
      report_type,
      portfolios: clean_portfolios,
      portfolios_to_cluster,
      group_by_owner_level,
      portfolio_roll_up_limit,
      classifiers: (classifiers || []).filter(item => !item.is_utt),
      evaluation_classifier_id,
      utt_class_ids: (classifiers || []).filter(item => item.is_utt).map(item => item.classifier_id),
      negatives_processing,
      multi_label,
      threshold: score_threshold,
      use_utt_superclasses: use_superclasses,
      utt_version
    })

  }, [
    evaluation_classifier_id,
    group_by_owner_level,
    portfolios,
    portfolio_roll_up_limit,
    classifiers,
    utt_version,
    is_nd_report,
    technology_partitioning,
    portfolios_to_cluster,
    negatives_processing,
    multi_label,
    score_threshold,
    selected_landscape_option,
    selected_new_families_option
  ])

  useEffect(() => {
    // Check for existing reports
    if (!should_check_existing_reports) return
    set_existing_reports_fetch_error(null)

    const report_input = build_report_input()

    Promise.all([check_for_existing_and_in_progress_reports(report_input), get_data_version()])
      .then(([{completed_report, running_report}, {data_version}]) => {
        const { existing_report, in_progress_report } = get_version_appropriate_existing_reports(completed_report, running_report, data_version)

        set_existing_report(existing_report)
        set_in_progress_report(in_progress_report)
        set_should_check_existing_reports(false)
      })
      .catch(error => {
        set_existing_report(null)
        set_in_progress_report(null)
        set_existing_reports_fetch_error(error)
        set_should_check_existing_reports(false)
      })
  }, [should_check_existing_reports, build_report_input])

  const redirect_to_created_external_id = useCallback((external_report_id) => {
    const subpath = get_subpath_for_report_link({is_nd_report, is_valuation_report, evaluation_classifier_id, alert_id})
    const url = `${REPORT}/${external_report_id}${subpath}`
    history.replace({pathname: url})
  }, [history, is_nd_report, is_valuation_report, evaluation_classifier_id, alert_id])

  useEffect(() => {
    if (!start_report) return

    const with_classifiers = (classifiers || []).length > 0
    const classifier_ids_for_tracking = with_classifiers ? get_list_of_hashed_classifier_ids(classifiers.map(classifier => classifier.classifier_id)) : null
    const classifier_ids_part = with_classifiers ? ` classifier_ids="${classifier_ids_for_tracking}"` : ''

    const report_input = build_report_input()

    track_report_builder_event(`action="build_report" report_type="${report_input.report_type}" classifiers="${with_classifiers ? 'yes' : 'no'}" context="builder"${classifier_ids_part}`)

    build_report(report_input, normalise_name(report_name))
      .then(external_report_id => {

        if (evaluation_classifier_id !== null) {
          return save_eval_report_selected_charts_in_state(external_report_id)
        }

        return external_report_id
      })
      .then((external_report_id) => {
        redirect_to_created_external_id(external_report_id)
      })
      .catch(handle_report_creation_error)
  }, [
    start_report,
    is_nd_report,
    build_report_input,
    report_name,
    classifiers,
    technology_partitioning,
    redirect_to_created_external_id,
    evaluation_classifier_id
  ])

  useEffect(() => {
    if (!is_calculate_orgs_total_size) return

    if (!portfolios || portfolios.length === 0) {
      set_is_calculate_orgs_total_size(false)
      set_portfolio_basket_orgs_total_size(0)
      return
    }

    get_portfolio_basket_orgs_total_size(portfolios)
      .then(total_size => {
        set_portfolio_basket_orgs_total_size(total_size)
        set_is_calculate_orgs_total_size(false)
      })
      .catch((error) => {
        send_error_to_sentry(error, {})
        set_is_calculate_orgs_total_size(false)
      })
  }, [is_calculate_orgs_total_size, portfolios])

  function on_set_portfolio_roll_up_limit(portfolio_roll_up_limit) {
    track_report_builder_event(`action="set_portfolio_roll_up_limit" limit="${portfolio_roll_up_limit}" context="builder"`)
    set_portfolio_roll_up_limit(portfolio_roll_up_limit)
    set_should_check_existing_reports(true)
  }

  function on_set_group_by_owner_level(group_by_owner_level) {
    track_report_builder_event(`action="set_group_by_owner_level" group_by_owner_level="${group_by_owner_level}" context="builder"`)
    set_group_by_owner_level(group_by_owner_level)
    set_should_check_existing_reports(true)
  }

  function on_set_multi_label(multi_label) {
    set_multi_label(multi_label)
    set_should_check_existing_reports(true)
  }

  function on_set_negatives_processing(negatives_processing) {
    set_negatives_processing(negatives_processing)
    set_should_check_existing_reports(true)
  }

  function on_set_score_threshold(threshold) {
    set_score_threshold(threshold)
    set_should_check_existing_reports(true)
  }

  function create_report_from_existing(existing_internal_report_id) {
    const report_type = get_report_type_from_input({portfolios, technology_partitioning, is_nd_report, selected_landscape_option, selected_new_families_option})

    track_report_builder_event(`action="select_cached" report_type="${report_type}" context="builder"`)

    const report_input = build_report_input()
    save_new_report_with_existing_internal_id({
      existing_internal_report_id,
      report_name: normalise_name(report_name),
      report_input
    })
      .then(redirect_to_created_external_id)
      .catch(handle_report_creation_error)
  }

  function handle_report_creation_error(error) {
    set_build_report_error(error)
    set_start_report(false)
    throw error
  }

  function on_toggle_is_multiple_org_search(updated_is_multiple_org_search) {
    if (updated_is_multiple_org_search !== is_multiple_org_search) {
      const new_query = {...qs.parse(location.search), search_multiple: updated_is_multiple_org_search}
      history.replace({pathname: location.pathname, search: `?${qs.stringify(new_query)}`})
      set_is_multiple_org_search(updated_is_multiple_org_search)
    }
  }

  function handle_save_portfolio_basket_as_list(company_list) {
    if (company_list) {
      // list has been saved successfully; add metadata to the relevant basket items
      const portfolio_basket_updated = portfolios.map(portfolio => is_org_type(portfolio) ? {
        ...portfolio,
        company_list
      } : portfolio)
      set_portfolios(portfolio_basket_updated)
    }
    fetch_user_created_company_lists()
  }

  function fetch_user_created_company_lists() {
    fetch_company_lists()
      .catch((error) => {
        set_fetch_user_company_lists_error(error)
        throw error
      })
      .then(user_company_lists => set_user_company_lists(user_company_lists))
  }

  function portfolio_search_mode_change(mode) {
    const {id} = mode || {}

    if (id == null) return

    const new_query = {...qs.parse(location.search), portfolio_search_mode: id}
    history.push({pathname: location.pathname, search: `?${qs.stringify(new_query)}`})
    set_orgs_for_suggestions(null)
    set_families_for_suggestions(null)

    set_portfolio_search_mode(mode)
  }

  function add_search_results_to_portfolio_basket(search_phrase, total) {
    const {basket=[], sizes=[]} = get_current_basket_for_non_landscape_portfolio()

    const portfolio_item = get_boolean_search_as_portfolio_item(get_normalised_search_phrase(search_phrase))

    const basket_items_with_the_same_name = basket.filter((item) => (item.name === portfolio_item.name))

    if (basket_items_with_the_same_name.length !== 0) {
      set_duplicated_basket_item_error(true)
      return
    }

    set_portfolios([...basket, portfolio_item])
    set_portfolio_basket_sizes([...sizes, total])
  }

  function add_org_set_to_portfolio_basket(org_set) {
    if (!org_set || !Array.isArray(org_set) || org_set.length === 0) return

    const {basket=[], sizes=[]} = get_current_basket_for_non_landscape_portfolio()

    const portfolio_basket_by_name = get_as_map(basket, 'name')

    const new_orgs = org_set.filter(org => portfolio_basket_by_name[org.name] == null)
    const new_basket_items = new_orgs.map(org => get_org_as_portfolio_item(org))
    const new_basket_items_sizes = new_orgs.map(org => (org || {}).size || 0)

    set_portfolios([...basket, ...new_basket_items])
    set_portfolio_basket_sizes([...sizes, ...new_basket_items_sizes])
    set_is_calculate_orgs_total_size(true)
  }

  function add_tag_set_to_portfolio_basket(tag, tag_value) {
    const {basket=[], sizes=[]} = get_current_basket_for_non_landscape_portfolio()

    const fetch_families_promise = (tag_value ?
      fetch_families_for_tag_value(tag_value.id) :
      fetch_families_for_tag_group(tag.id))
    const portfolio_name = (tag_value ?
      tag.name + ': ' +
      tag_value.value : tag.name)

    fetch_families_promise
      .then(tagged_families_array => {
        const tagged_families = tagged_families_array.reduce((total, families_i)=> {return total.concat(families_i)},[])
        const portfolio_item = get_family_tag_as_portfolio_item(portfolio_name, tagged_families)

        if (is_valuation_report) {
          portfolio_item['group_by_owner'] = false
        }

        const basket_items_with_the_same_name = basket.filter((item) => (item.name === portfolio_item.name))

        if (basket_items_with_the_same_name.length !== 0) {
          set_duplicated_basket_item_error(true)
          return
        }

        set_portfolios([...basket, portfolio_item])
        set_portfolio_basket_sizes([...sizes, (tagged_families || []).length])
      })
      .catch(error => {
        set_invalid_tagged_families(error)
        throw error
      })
  }

  function has_landscape_portfolio() {
    return _.some(portfolios || [], portfolio => portfolio.is_landscape)
  }

  function get_current_basket_for_non_landscape_portfolio() {
    return has_landscape_portfolio() ? {basket: [], sizes: []} : {basket: portfolios || [], sizes: portfolio_basket_sizes || []}
  }

  function update_orgs_in_portfolio_basket({add, remove}) {
    const {basket : current_basket=[], sizes=[]} = get_current_basket_for_non_landscape_portfolio()

    const orgs_to_add = add || []
    const orgs_to_remove = remove || []

    let basket = [...current_basket]
    let basket_sizes = [...sizes]

    orgs_to_remove.forEach(org => {
      let idx = -1

      basket.forEach((item, i) => {
        if (is_org_type(item) && are_same_orgs(item, org)) {
          idx = i
        }
      })

      if (idx !== -1) {
        basket.splice(idx, 1)
        basket_sizes.splice(idx, 1)
      }
    })

    orgs_to_add.forEach(org => {
      basket.push(get_org_as_portfolio_item(org))
      basket_sizes.push((org || {}).size || 0)
    })

    set_portfolios(basket)
    set_portfolio_basket_sizes(basket_sizes)
    set_is_calculate_orgs_total_size(true)
  }

  function add_custom_search_to_portfolio_basket(patent_search) {
    const {basket=[], sizes=[]} = get_current_basket_for_non_landscape_portfolio()

    const {name, found_families, group_by_owner, table_rows} = patent_search

    const found = table_rows ? filter_matched_patents(table_rows) : []

    const patent_upload_item = {
      name,
      pat_fam_ids: found_families,
      ...(is_valuation_report) ? {group_by_owner: false} : {group_by_owner},
      lines: found.map(row => row.lineno),
      inputs: found.map(row => row.source),
      publications: found.map(row => row.publication)
    }

    const portfolio_item = get_patent_upload_as_portfolio_item(patent_upload_item)

    const basket_items_with_the_same_name = basket.filter((item) => (item.name === portfolio_item.name))

    if (basket_items_with_the_same_name.length !== 0) {
      set_duplicated_basket_item_error(true)
      return
    }

    set_portfolios([...basket, portfolio_item])
    set_portfolio_basket_sizes([...sizes, found_families.length])
  }

  function rename_portfolio_basket_item(item_id, new_name) {
    const item = portfolios[item_id] || {}
    const {name, original_name} = item
    update_portfolio_basket_item({...item, name: new_name, original_name: original_name || name}, item_id)
  }

  function update_group_by_owner_flag(item_id) {
    const item = portfolios[item_id] || {}

    const {group_by_owner} = item

    update_portfolio_basket_item({...item, group_by_owner: (!group_by_owner)}, item_id)
    set_should_check_existing_reports(true)
  }

  function update_portfolio_basket_item(item, item_id) {
    let new_portfolio_basket = [...portfolios]

    new_portfolio_basket[item_id] = item

    set_portfolios(new_portfolio_basket)
  }

  function update_all_portfolio_basket_items(idxs, property_name, value) {
    const idxs_set = new Set(idxs)
    const new_portfolio_basket = portfolios.map((item, idx) => {
      if (idxs_set.has(idx)) {
        return {...item, [property_name]: value}
      }
      return item
    })
    set_portfolios(new_portfolio_basket)
  }

  function update_classifiers_selection(selections) {
    set_classifiers(selections)
  }

  function clear_portfolio_basket() {
    set_portfolios([])
    set_portfolio_basket_sizes([])
    set_portfolio_basket_orgs_total_size(0)
  }

  function clear_classifiers_selection() {
    set_classifiers(null)
  }

  function delete_from_portfolio_basket(basket_item_idx_to_delete) {
    let new_portfolio_basket = [...portfolios]
    let new_portfolio_basket_sizes = [...portfolio_basket_sizes]

    const item = portfolios[basket_item_idx_to_delete]
    new_portfolio_basket.splice(basket_item_idx_to_delete, 1)
    new_portfolio_basket_sizes.splice(basket_item_idx_to_delete, 1)

    set_portfolios(new_portfolio_basket)
    set_portfolio_basket_sizes(new_portfolio_basket_sizes)
    set_is_calculate_orgs_total_size(is_org_type(item) || is_org_group_type(item))
  }

  function delete_from_technology_basket(basket_item_idx_to_delete) {
    let updated_classifiers = [...classifiers]

    updated_classifiers.splice(basket_item_idx_to_delete, 1)
    set_classifiers(updated_classifiers)
  }

  function on_start_report() {
    set_start_report(true)
  }

  function update_org_group({add, remove}) {
    const orgs_to_add = add || []
    const orgs_to_remove = remove || []

    let updated_group = [...org_group]

    orgs_to_remove.forEach(org => {
      let idx = -1
      updated_group.forEach((org_in_group, i) => {
        if (are_same_orgs(org_in_group, org)) {
          idx = i
        }
      })

      if (idx !== -1) {
        updated_group.splice(idx, 1)
      }
    })

    orgs_to_add.forEach(org => {
      updated_group = [...updated_group, org]
    })

    set_org_group(updated_group)
  }

  function remove_from_grouping(i) {
    track_report_builder_event('action="remove_from_org_group" obj="org"')
    const group_copy = [...org_group]
    group_copy.splice(i, 1)

    set_org_group(group_copy)
  }

  function cancel_grouping() {
    track_report_builder_event('action="reset" obj="org_group"')
    set_org_group([])
  }

  function add_group_to_portfolio_basket(group_name) {
    track_report_builder_event('action="add_to_basket" obj="org_group"')

    const portfolio_item = get_org_group_as_portfolio_item(org_group, group_name)
    const group_sizes = org_group.map(org => (org || {}).size || 0)

    const path = location.pathname
    const new_query = {...qs.parse(location.search), org_search: ''}
    history.replace({pathname: path, search: `?${qs.stringify(new_query)}`})

    set_org_group([])
    set_portfolios([...portfolios || [], portfolio_item])
    set_portfolio_basket_sizes([...portfolio_basket_sizes || [], sum(group_sizes)])
    set_is_calculate_orgs_total_size(true)
  }

  function get_similar_orgs_for_basket_item(i) {
    const item = portfolios[i]
    on_toggle_is_multiple_org_search(false)

    if (is_org_type(item) || is_org_group_type(item)) {
      return get_similar_orgs_for_organisation(item)
    }

    if (is_patent_families_type(item)) {
      return get_similar_org_for_patent_upload(item)
    }

    if (is_family_tag_type(item)) {
      return get_similar_org_for_family_tag(item)
    }
  }

  function get_similar_orgs_for_organisation(item) {
    const {name, members} = item

    set_portfolio_search_mode(PORTFOLIO_SEARCH_TYPE_BY_ID.org_search)
    set_orgs_for_suggestions({name, items: is_org_group_type(item) ? members : [item]})
    set_families_for_suggestions(null)
  }

  function get_similar_org_for_patent_upload(item) {
    const {name, pat_fam_ids} = item

    set_portfolio_search_mode(PORTFOLIO_SEARCH_TYPE_BY_ID.upload)
    set_families_for_suggestions({name, items: pat_fam_ids})
    set_orgs_for_suggestions(null)
  }

  function get_similar_org_for_family_tag(item) {
    const {name, pat_fam_ids} = item

    set_portfolio_search_mode(PORTFOLIO_SEARCH_TYPE_BY_ID.tag_sets)
    set_families_for_suggestions({name, items: pat_fam_ids})
    set_orgs_for_suggestions(null)
  }

  function reset_orgs_for_suggestions() {
    set_orgs_for_suggestions(null)
  }

  function reset_families_for_suggestions() {
    set_families_for_suggestions(null)
  }

  function rename_user_company_list(selected_company_list_id, new_name) {
    const updated_lists = user_company_lists.map(list => {
      const {company_list_id} = list

      if (company_list_id === selected_company_list_id) {
        return {...list, list_name: new_name}
      }

      return list
    })

    set_user_company_lists(updated_lists)
  }

  function change_user_company_list_sharing_status(selected_company_list_id) {
    const updated_lists = user_company_lists.map(list => {
      const {company_list_id, is_shared} = list

      if (company_list_id === selected_company_list_id) {
        return {...list, is_shared: !is_shared}
      }

      return list
    })

    set_user_company_lists(updated_lists)
  }

  function change_technology_partitioning(new_technology_partitioning) {
    set_classifiers(null)

    const {type: new_type} = new_technology_partitioning || {}

    set_portfolios_to_cluster(TECH_PARTITIONING_TYPE_CLUSTERING !== new_type ? null : (is_report_big ? [] : portfolios.map((item, i) => i)))

    set_technology_partitioning(new_technology_partitioning)
  }

  function toggle_portfolio_to_cluster(item_id) {
    const updated_portfolio_to_cluster = ((portfolios_to_cluster || []).indexOf(item_id) > -1 ? portfolios_to_cluster.filter(item => item !== item_id) : [...portfolios_to_cluster || [], item_id]).sort()
    set_portfolios_to_cluster(updated_portfolio_to_cluster)
  }

  function get_default_negatives_processing_type() {
    if (is_report_big) return NEGATIVES_PROCESSING_EXCLUDE

    if (is_valuation_report) return NEGATIVES_PROCESSING_INCLUDE

    const include_unrelated_technologies_from_settings = should_include_unrelated_technologies(user_settings)

    if (is_nd_report) {
      return ((include_unrelated_technologies_from_settings == null) || !include_unrelated_technologies_from_settings) ?
        NEGATIVES_PROCESSING_EXCLUDE :
        NEGATIVES_PROCESSING_INCLUDE
    }

    if ((include_unrelated_technologies_from_settings != null) && !include_unrelated_technologies_from_settings) return NEGATIVES_PROCESSING_EXCLUDE

    return NEGATIVES_PROCESSING_UTT
  }

  function get_is_all_selected_for_clustering() {
    return (portfolios_to_cluster || []).length === (portfolios || []).length
  }

  function can_builder_continue() {
    if (REPORT_BUILDER_MODE_ID_PORTFOLIO === mode) {
      if (portfolio_search_mode.id === PORTFOLIO_SEARCH_TYPE_LANDSCAPE_SEARCH_ID) return true
      if (is_fetching_org_set || is_calculate_orgs_total_size) return false
      if ((portfolios || []).length === 0) return false
      return true
    }

    if (REPORT_BUILDER_MODE_ID_TECHNOLOGY === mode) {
      const has_classifiers = (classifiers || []).length !== 0
      if (is_report_too_big && !(has_classifiers || is_aistemos(user))) return false
      const {type} = technology_partitioning || {}
      if (!type) return false
      if (type === TECH_PARTITIONING_TYPE_UTT && has_landscape_portfolio(portfolios) && !has_classifiers) return false
      return (type === TECH_PARTITIONING_TYPE_CLASSIFIER) ? has_classifiers : true
    }

    return is_valid_report_name(report_name)
  }

  function on_click_next() {
    const next_step = (REPORT_BUILDER_MODE_ID_PORTFOLIO === mode) ? REPORT_BUILDER_MODE_ID_TECHNOLOGY : REPORT_BUILDER_MODE_ID_OPTIONS

    const is_tech_partitioning_ready = (base_report_id != null) || ((input_id != null) && (technology_partitioning != null))

    if (REPORT_BUILDER_MODE_ID_TECHNOLOGY === next_step) {
      if (PORTFOLIO_SEARCH_TYPE_LANDSCAPE_SEARCH_ID === portfolio_search_mode.id) {
        const {name, search_phrase, id} = selected_landscape_option || {}

        const is_all_families = (id === ALL_FAMILIES_LANDSCAPE_OPTION_ID)

        const landscape_option = is_all_families ? { ...S3_ALL_FAMILIES_PORTFOLIO, static_name: ID_TO_LANDSCAPE_GROUP[ALL_FAMILIES_LANDSCAPE_OPTION_ID].name } : { ...get_boolean_search_as_portfolio_item(search_phrase), name }
        const time_option = selected_new_families_option != null ? get_accession_date_portfolio_item(selected_new_families_option) : null

        const portfolio = time_option == null ?
          landscape_option :
          (is_all_families ?
            time_option :
            {...get_boolean_search_as_portfolio_item([landscape_option.search_term, time_option.search_term].join(' AND ')), name: [landscape_option.name, time_option.name].join(', ')}
          )

        set_portfolios([{...portfolio, is_landscape: true}])
        set_portfolio_basket_sizes([])
        set_portfolio_basket_orgs_total_size(0)
      }

      if (!is_tech_partitioning_ready) {
        set_technology_partitioning(has_classifiers ? { type: TECH_PARTITIONING_TYPE_CLASSIFIER } : get_technology_partitioning_by_utt({ utt_version }))
      }
    }

    if (REPORT_BUILDER_MODE_ID_OPTIONS === next_step) {
      if (
        (negatives_processing == null) ||
        (is_report_big && (negatives_processing.type !== NEGATIVES_PROCESSING_EXCLUDE))
      ) {
        const negatives_processing_type = is_landscape ? NEGATIVES_PROCESSING_EXCLUDE : get_default_negatives_processing_type()
        set_negatives_processing(get_negatives_processing({ type: negatives_processing_type, utt_version }))
      }

      if (multi_label == null) {
        set_multi_label(is_nd_report === true)
      }

      if (score_threshold == null) {
        set_score_threshold(CLASSIFIER_SCORE_THRESHOLD_DEFAULT)
      }

      if (report_name == null) {

        const report_name = get_default_report_name({
          portfolios,
          classifiers,
          technology_partitioning,
          selected_landscape_option,
          selected_new_families_option,
          is_nd_report
        })

        set_report_name(report_name)
      }

      set_should_check_existing_reports(true)
    }

    set_mode(next_step)
  }

  function move_back_to_portfolio_search() {
    set_mode(REPORT_BUILDER_MODE_ID_PORTFOLIO)
    if (!portfolio_search_mode) {
      set_portfolio_search_mode(base_report_id != null ? PORTFOLIO_SEARCH_TYPE_BY_ID[PORTFOLIO_SEARCH_TYPE_ORG_SEARCH_ID] : PORTFOLIO_SEARCH_TYPE_BY_ID[PORTFOLIO_SEARCH_TYPE_LANDSCAPE_SEARCH_ID])
    }
  }

  function move_back_to_technology() {
    set_mode(REPORT_BUILDER_MODE_ID_TECHNOLOGY)
  }

  function on_click_prev() {
    set_report_name(null)
    REPORT_BUILDER_MODE_ID_OPTIONS === mode ? move_back_to_technology() : move_back_to_portfolio_search()
  }

  if (error_fetching_initial_data) {
    return (
      <ContainerFullWidth>
        <ErrorBody
          error={error_fetching_initial_data}
          context='fetching initial data'
        />
      </ContainerFullWidth>
    )
  }

  if (fetch_user_company_lists_error) {
    return (
      <ErrorModal
        on_hide={() => set_fetch_user_company_lists_error(null)}
        error={fetch_user_company_lists_error}
        context='fetching user created org lists'
      />
    )
  }

  if (build_report_error) {
    return (
      <ContainerFullWidth>
        <ErrorBody
          error={build_report_error}
          context='building a report'
        />
      </ContainerFullWidth>
    )
  }

  if (loading_base_report_error) {
    return (
      <ContainerFullWidth>
        <ErrorBody
          on_hide={() => set_loading_base_report_error(null)}
          error={loading_base_report_error}
          context={`fetching base report data${is_not_found(loading_base_report_error) ? '. Input selections could not be found for the report' : ''}`}
        />
      </ContainerFullWidth>
    )
  }

  if (loading_clickthrough_input_error) {
    return (
      <ContainerFullWidth>
        <ErrorBody
          error={loading_clickthrough_input_error}
          context={'fetching report input by id'}
        />
      </ContainerFullWidth>
    )
  }

  if (start_report) {
    return (
      <div className='mt-3 w-100'>
        <ProgressBanner title='Your report is being created.' />
      </div>
    )
  }

  if ((is_nd_report && !has_cipher_product(user, PRODUCT_ND)) || (!is_nd_report && !is_creator(user))) {
    return (
      <ReportBuilderNoAccessPanel/>
    )
  }

  if (is_fetching_initial_data) {
    return (
      <div className='mt-3 w-100'>
        <ProgressBanner title='Fetching initial data.' />
      </div>
    )
  }

  if (show_spinner) {
    return (
      <div className={cn('d-lg-flex mt-3', s.block)}>
        <div className='text-center w-100'>
          <div>Fetching results</div>
          <Spinner/>
        </div>
      </div>
    )
  }

  const can_continue = can_builder_continue()

  const groupable_portfolio_idxs = get_all_groupable_idxs(portfolios)
  const has_groupable_portfolios = (groupable_portfolio_idxs || []).length > 0
  const has_all_group_by_selected = is_all_group_by_selected(portfolios)

  const is_all_selected_for_clustering = get_is_all_selected_for_clustering()

  const {type: selected_type} = technology_partitioning || {}

  const is_landscape = has_landscape_portfolio()

  const show_owner_level_option = has_groupable_portfolios
  const show_portfolio_rollup_option = is_poweruser(user)
  const show_group_by_options = has_groupable_portfolios && !is_landscape

  const show_any_portfolio_options = !is_valuation_report && (show_owner_level_option || show_portfolio_rollup_option || show_group_by_options)

  const show_multi_label_option = (classifiers || []).length > 1
  const show_negatives_processing_option = !(is_valuation_report || is_landscape)
  const show_score_threshold_option = !is_nd_report && ((technology_partitioning || {}).type !== TECH_PARTITIONING_TYPE_UTT)

  const show_any_technology_setting = (classifiers || []).length > 0 && (show_multi_label_option || show_negatives_processing_option || show_score_threshold_option)

  return (
    <ReportBasketContext.Provider value={{
      portfolio_basket: portfolios,
      portfolio_basket_sizes,
      portfolio_basket_orgs_total_size,
      classifiers,
      org_group,
      technology_partitioning
    }}>
      <div className={cn('d-flex', s.block)}>

        {duplicated_basket_item_error &&
          <Modal on_hide={() => set_duplicated_basket_item_error(false)}>
            <div>This item is already in the basket</div>
          </Modal>
        }

        {invalid_portfolios &&
          <ScrollModal on_hide={() => set_invalid_portfolios(null)}>
            <InvalidOrganisationsWarning
              invalid_portfolios={invalid_portfolios}
              suggested_action_text='Please re-add them using the search system.'
            />
          </ScrollModal>
        }

        {unavailable_classifiers && unavailable_classifiers.length > 0 &&
          <ScrollModal
            title={`${pluralise_text(unavailable_classifiers.length, 'Classifier')} not found`}
            className='h-50 w-75'
            on_hide={() => set_unavailable_classifiers(null)}
          >
            <UnavailableClassifiersWarning
              unavailable_classifiers={unavailable_classifiers}
              suggested_action_text={has_classifiers ? `Any other classifiers needed for the report can be added by going to '${_.isEmpty(classifiers) ? SELECT_CLASSIFIERS_LINK_TEXT : EDIT_CLASSIFIER_SELECTION_LINK_TEXT}'.`: null}
            />
          </ScrollModal>
        }

        {invalid_tagged_families &&
          <ErrorModal
            error={invalid_tagged_families}
            on_hide={() => set_invalid_tagged_families(null)}
            context='error fetching tagged families'
          />
        }

        {verify_base_report_portfolios_error &&
          <ErrorModal
            error={verify_base_report_portfolios_error}
            on_hide={() => set_verify_base_report_portfolios_error(null)}
            context='verifying base report organisations'
          />
        }

        {existing_reports_fetch_error &&
          <ErrorModal
            context={'fetching reports from cache'}
            error={existing_reports_fetch_error}
            on_hide={() => set_existing_reports_fetch_error(null)}
          />
        }

        {loading_base_report &&
          <div className='mt-3 w-100'>
            <ProgressBanner title={`Loading report ${report_name ? `"${report_name}"` : (base_report_id || '')}`} />
          </div>
        }

        {!loading_base_report &&
          <div className={cn('position-relative', s.container__store)}>
            <div className={cn('d-flex justify-content-center', s.store_box, s.progress_display)}>
              <Stepper
                steps={[
                  {
                    id: REPORT_BUILDER_MODE_ID_PORTFOLIO,
                    label: 'In report',
                    subtitle: 'Search and add to report',
                    on_click: mode === REPORT_BUILDER_MODE_ID_PORTFOLIO ? null : move_back_to_portfolio_search
                  },
                  {
                    id: REPORT_BUILDER_MODE_ID_TECHNOLOGY,
                    label: 'Technologies',
                    subtitle: 'Choose how to group families',
                    on_click: mode === REPORT_BUILDER_MODE_ID_OPTIONS ? move_back_to_technology : null
                  },
                  {
                    id: REPORT_BUILDER_MODE_ID_OPTIONS,
                    label: 'Options',
                    subtitle: 'Tune your report'
                  },
                ]}

                active_step_id={mode}

                can_continue={can_continue}
              />
            </div>

            <div className={cn('position-relative', s.store_box, s.builder_container)}>
              {(mode === REPORT_BUILDER_MODE_ID_PORTFOLIO) &&

                <div className='position-relative w-100 h-100'>

                  <PortfolioSearchNavigation
                    active={portfolio_search_mode}
                    disabled_options={base_report_id != null ? [PORTFOLIO_SEARCH_TYPE_LANDSCAPE_SEARCH_ID] : []}
                    on_change={portfolio_search_mode_change}
                    is_nd_report={(is_nd_report === true)}
                    is_valuation_report={is_valuation_report === true}
                    org_group_started={is_org_group}

                    className={cn('pt-3', s.portfolio_search_nav)}
                  />

                  <div className={s.portfolio_search_scroll_zone}>
                    {(PORTFOLIO_SEARCH_TYPE_BY_ID.landscape === portfolio_search_mode) &&
                      <LandscapeAsPortfolio
                        selected_landscape_option={selected_landscape_option}
                        selected_new_families_option={selected_new_families_option}
                        on_set_selected_landscape_option={(item) => {set_selected_landscape_option(item)}}
                        on_set_selected_new_families_option={set_selected_new_families_option}

                        className='mt-2 mb-3 pe-2'
                      />
                    }

                    {(PORTFOLIO_SEARCH_TYPE_BY_ID.org_search === portfolio_search_mode) &&
                      <div className='mb-3 p-2'>
                        {is_multiple_org_search ?
                          <OrgSearchMultiple
                            portfolio_basket={portfolios || []}
                            update_basket_handler={update_orgs_in_portfolio_basket}
                            update_group_handler={update_org_group}
                            external_items_for_suggestions={orgs_for_suggestions}
                            reset_items_for_suggestions_handler={reset_orgs_for_suggestions}
                            on_search_mode_change={() => on_toggle_is_multiple_org_search(false)}
                            enable_remove_action={true}
                            enable_ignore_action={true}
                            inputClassName={cn('h-100', cs.resize_disabled, cs.overflow_y_scroll)}
                          /> :
                          <OrgSearch
                            update_basket_handler={update_orgs_in_portfolio_basket}
                            update_group_handler={update_org_group}
                            external_items_for_suggestions={orgs_for_suggestions}
                            reset_items_for_suggestions_handler={reset_orgs_for_suggestions}
                            toggle_is_search_multiple={() => on_toggle_is_multiple_org_search(true)}

                            add_org_set_to_basket_handler={add_org_set_to_portfolio_basket}
                            on_start_fetching_org_set={() => set_is_fetching_org_set(true)}
                            on_end_fetching_org_set={() => set_is_fetching_org_set(false)}
                            user_company_lists={user_company_lists}
                            user_company_list_updated_handler={fetch_user_created_company_lists}
                            user_company_list_rename_handler={rename_user_company_list}
                            user_company_list_sharing_handler={change_user_company_list_sharing_status}
                            invalid_portfolios_in_list_handler={(invalid_portfolios) => set_invalid_portfolios(invalid_portfolios)}
                          />
                        }
                      </div>
                    }

                    {(PORTFOLIO_SEARCH_TYPE_BY_ID.tag_sets === portfolio_search_mode) &&
                      <FamilyTagSectionsDisplay
                        add_to_basket_handler={add_tag_set_to_portfolio_basket}
                        portfolio_name={(families_for_suggestions || {}).name}
                        portfolio_items={(families_for_suggestions || {}).items}
                        on_click_similar_portfolio={update_orgs_in_portfolio_basket}
                        update_group_handler={update_org_group}
                        reset_similar_portfolio={reset_families_for_suggestions}
                        className='pe-2'
                      />
                    }

                    {(PORTFOLIO_SEARCH_TYPE_BY_ID.families_search === portfolio_search_mode) &&
                      <PatentFamilyGlobalSearch
                        results_action_handler={add_search_results_to_portfolio_basket}
                        controls_row_className={list_view_styles.controls_row} // for setting sticky top
                        table_header_className={list_view_styles.table_header} // for setting sticky top

                        className='pe-2'
                      />
                    }

                    {(PORTFOLIO_SEARCH_TYPE_BY_ID.upload === portfolio_search_mode) &&
                      <PatentUpload
                        update_group_handler={update_org_group}
                        parsed_results_action_handler={add_custom_search_to_portfolio_basket}
                        on_select_org_handler={update_orgs_in_portfolio_basket}
                        external_items_for_suggestions={families_for_suggestions}
                        reset_items_for_suggestions_handler={reset_families_for_suggestions}
                        job_id={custom_upload_job_id}
                        is_wizard={false}
                        enable_rename={true}
                        className='pe-2'
                      />
                    }
                  </div>
                </div>
              }

              {(mode === REPORT_BUILDER_MODE_ID_TECHNOLOGY) &&
                <div className={cn('position-relative w-100 h-100', s.technology_scroll_zone)}>
                  <TechnologySelector
                    technology_partitioning={technology_partitioning}
                    on_change_technology_partitioning={change_technology_partitioning}
                    selected_classifiers={classifiers}
                    has_classifiers={has_classifiers}
                    is_nd_report={is_nd_report}
                    is_valuation_report={is_valuation_report}
                    is_landscape={is_landscape}

                    utt_version={utt_version}
                    is_report_big={is_report_big}
                    is_report_too_big={is_report_too_big}
                    className='pt-3'
                  />

                  {(selected_type === TECH_PARTITIONING_TYPE_CLASSIFIER) &&
                    <div className='mt-3'>
                      <h3>Select classifiers</h3>
                      <TechnologiesDisplay
                        classifier_groups={classifier_groups}

                        selected_classifier_group_id={selected_classifier_group_id}
                        selected_classifiers={classifiers}
                        on_select_classifiers={update_classifiers_selection}
                        on_select_classifier_group={set_selected_classifier_group_id}

                        classifiers_landscape_limit={is_landscape ? classifiers_landscape_limit : null}
                      />
                    </div>
                  }

                  {(selected_type === TECH_PARTITIONING_TYPE_UTT) && is_landscape &&
                    <div className='mt-3'>
                      <h3>Select UTT class</h3>
                      <TechnologiesDisplay
                        utt_group={utt_classes_group}
                        selected_classifier_group_id={'utt'}
                        selected_classifiers={classifiers}
                        on_select_classifiers={update_classifiers_selection}
                        on_select_classifier_group={set_selected_classifier_group_id}

                        utt_landscape_limit={selected_new_families_option != null ? null : 1}
                      />
                    </div>
                  }

                  {(selected_type === TECH_PARTITIONING_TYPE_CLUSTERING) &&
                    <div className='mt-3'>
                      {!is_report_big &&
                        <h3>Select portfolios</h3>
                      }

                      {!is_report_big &&
                        <PortfolioCheckboxList
                          items={portfolios.map((item,i) => ({...item, idx: i}))}
                          selected_items_idxs={portfolios_to_cluster}
                          is_header_checkbox_selected={is_all_selected_for_clustering}
                          on_click_from_item_checkbox={toggle_portfolio_to_cluster}
                          on_click_from_header_checkbox={() => set_portfolios_to_cluster(is_all_selected_for_clustering ? [] : portfolios.map((item, i) => i))}
                        />
                      }
                    </div>
                  }
                </div>
              }

              {(mode === REPORT_BUILDER_MODE_ID_OPTIONS) &&
                <div className={cn('pt-3 position-relative w-100 h-100', s.technology_scroll_zone)}>
                  <h3>Report options</h3>
                  <ReportNameInput
                    report_name={report_name}
                    on_change={(value) => set_report_name(remove_not_allowed_chars_from_text(value))}
                  />

                  {show_any_technology_setting  &&
                    <div>
                      <h4>Technology settings</h4>

                        {show_multi_label_option &&
                          <MultiLabelOptions
                            technology_multi_label={multi_label}
                            update_technology_multi_label={on_set_multi_label}
                            className='mb-3'
                          />
                        }

                        {show_negatives_processing_option &&
                          <NegativesProcessingOptions
                            selected={negatives_processing.type}
                            is_nd_report={is_nd_report}
                            on_select={(id) => on_set_negatives_processing(get_negatives_processing({type: id, utt_version}))}

                            is_report_big={is_report_big}
                            className='mb-3'
                          />
                        }

                        {show_score_threshold_option &&
                          <ScoreThresholdControl
                            score_threshold_objs={AVAILABLE_SCORE_THRESHOLDS}
                            selected_score_threshold_value={score_threshold}
                            on_change_score_threshold={on_set_score_threshold}
                            user={user}
                            className='mb-3'
                          />
                        }
                    </div>
                  }

                  {show_any_portfolio_options &&
                    <div>
                      <h4>Organisations</h4>
                      {show_owner_level_option &&
                        <OwnerLevelControl
                          selected_level={group_by_owner_level}
                          on_select={on_set_group_by_owner_level}
                          className='mb-3'
                        />
                      }

                      {show_portfolio_rollup_option &&
                        <PortfolioRollupLimitControl
                          portfolio_roll_up_limit={portfolio_roll_up_limit}
                          set_portfolio_roll_up_limit={on_set_portfolio_roll_up_limit}
                          className='mb-3'
                        />
                      }

                      {show_group_by_options &&
                        <div>
                          <label>Group by owner:</label>

                          <PortfolioCheckboxList
                            items={groupable_portfolio_idxs.map(idx => ({...portfolios[idx], idx}))}
                            selected_items_idxs={groupable_portfolio_idxs.filter(idx => portfolios[idx].group_by_owner === true)}
                            on_click_from_item_checkbox={update_group_by_owner_flag}
                            is_header_checkbox_selected={has_all_group_by_selected}
                            on_click_from_header_checkbox={() => update_all_portfolio_basket_items(groupable_portfolio_idxs, 'group_by_owner', !has_all_group_by_selected)}
                          />

                        </div>
                      }
                    </div>
                  }
                </div>
              }

            </div>

            <ProgressPrompt
              className={cn(s.progress_prompt)}

              on_click_prev={mode === REPORT_BUILDER_MODE_ID_PORTFOLIO ? null : on_click_prev}
              on_click_next={mode === REPORT_BUILDER_MODE_ID_OPTIONS ? null : on_click_next}
              on_click_finish={mode === REPORT_BUILDER_MODE_ID_OPTIONS ? on_start_report : null}
              on_click_use_cached_report={mode === REPORT_BUILDER_MODE_ID_OPTIONS && existing_report ? () => create_report_from_existing(existing_report.report_id) : null}
              on_click_use_running_report={mode === REPORT_BUILDER_MODE_ID_OPTIONS && in_progress_report ? () => create_report_from_existing(in_progress_report.report_id) : null}

              can_continue={can_continue}
            />
          </div>
        }

        {!loading_base_report &&
          <div className={cn('position-relative', s.container__basket)}>
            <div className={cn(s.basket_box, s.basket, {[s.basket__with_warning]: (is_report_big || is_report_too_big || (existing_report && (REPORT_BUILDER_MODE_ID_OPTIONS === mode)))})}>
              {!is_org_group &&
                <ReportBuilderBasket
                  delete_from_portfolio_basket_handler={delete_from_portfolio_basket}
                  rename_portfolio_basket_item={rename_portfolio_basket_item}
                  clear_portfolio_basket_handler={clear_portfolio_basket}
                  report_builder_mode={mode}
                  report_builder_change_search_mode_handler={portfolio_search_mode_change}
                  report_builder_search_mode={portfolio_search_mode}
                  show_technologies_basket={has_classifiers}
                  delete_from_technology_basket_handler={delete_from_technology_basket}
                  clear_technology_basket_handler={clear_classifiers_selection}
                  get_similar_orgs_handler={get_similar_orgs_for_basket_item}
                  portfolio_basket_save_handler={handle_save_portfolio_basket_as_list}
                  user_company_lists={user_company_lists}
                  is_calculating_report_size={is_calculate_orgs_total_size}
                  portfolio_size={portfolio_size}
                  is_report_big={is_report_big}
                  is_report_too_big={is_report_too_big}
                  is_landscape={is_landscape}
                  portfolios_to_cluster={portfolios_to_cluster}
                  user={user}
                />
              }
              {is_org_group &&
                <OrgGroup
                  orgs={org_group}
                  on_remove_org={remove_from_grouping}
                  on_cancel={cancel_grouping}
                  on_submit={add_group_to_portfolio_basket}
                />
              }
            </div>

            {is_report_too_big &&
              <ReportBuilderBasketStopMessage user_has_classifiers={has_classifiers} className={cn(s.basket_box, s.basket_warning)}/>
            }

            {is_report_big && !is_report_too_big &&
              <ReportBuilderBasketWarning className={cn(s.basket_box, s.basket_warning)} />
            }

            {existing_report && (REPORT_BUILDER_MODE_ID_OPTIONS === mode) &&
              <ReportBuilderBasketInfo
                message={`There is a cached version of this exact report that was created ${moment(existing_report.date || existing_report.timestamp).fromNow()}`}
                className={cn(s.basket_box, s.basket_warning)}
              />
            }
          </div>
        }
      </div>
    </ReportBasketContext.Provider>
  )
}

export default withRouter(withUser(ReportBuilder))