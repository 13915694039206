import React, { useState } from 'react'
import cn from 'classnames'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

import { RadiobuttonWithLabel } from './widgets/RadiobuttonWithLabel.js'
import { CIPHER_DEFAULT, CUSTOM_SET } from '../constants/default_chart_selection.js'
import TextLink from './widgets/TextLink.js'
import { UserChartSetsList } from './viewer/SelectionMenu.js'
import Tooltip, { InfoPopover } from './widgets/Tooltip.js'
import { InfoIcon } from './widgets/IconSet.js'
import {
  get_default_utt_version,
  get_language_preference,
  get_report_region_grouping,
  get_show_ungrouped_families,
  get_starting_page,
  has_charts_in_thumbnails,
  should_include_unrelated_technologies,
  should_notify_before_report_expiry,
  get_save_knn_searches
} from '../utils/user_settings_utils.js'
import { is_IE11 } from '../utils/browser_utils.js'
import { get_default_chart_selection_value } from '../utils/default_chart_selection_utils.js'
import { REPORT_REGION_GROUPING_OPTIONS } from '../constants/regions.js'
import { PrimaryButton, TertiaryButton } from './widgets/Button.js'
import { SELECTED_SUBPATH } from '../constants/viewer_paths.js'
import { has_utt, is_creator, is_view_only_user } from '../utils/user_permissions.js'
import { withUser } from './UserContext.js'
import { UTT_VERSIONS } from '../model/utt_version.js'
import BaseDropdown from './widgets/BaseDropdown.js'
import { LANGUAGE_CODE_TO_OPTION, LANGUAGE_OPTIONS } from '../model/language_prefs.js'
import Label from './widgets/Label.js'
import { is_tech_discovery } from '../utils/utils.js'

import s from './UserSettingsDisplay.module.scss'

const BROWSER_IS_IE_11 = is_IE11()

const SimilarFamiliesSearchHistorySetting = (
  {
    similar_families_search_setting,
    on_change_similar_families_search_setting
  }) => {
  return (
    <>
      <div className='mt-3 d-flex'>
        <Label>Similar families search history:</Label>
        <InfoPopover is_in_modal={true} toggler={<span className={cn('ms-1', s.tooltip_trigger)}><InfoIcon/></span>}>
          By default, your search history is disabled. When enabled, your search history lets you review your searches under "Pinned and recent searches."
        </InfoPopover>
      </div>
      <RadiobuttonWithLabel
        is_checked={similar_families_search_setting === true}
        on_click={() => on_change_similar_families_search_setting(true)}
        label='Save'
      />
      <RadiobuttonWithLabel
        is_checked={similar_families_search_setting === false}
        on_click={() => on_change_similar_families_search_setting(false)}
        label='Don&apos;t save'
      />
    </>
  )
}

const LanguageSetting = (
  {
    language_preference,
    on_change_language_setting
  }) => {
  return (
    <>
      <div className='mt-2 d-flex'>
        <Label>Language preference:</Label>
        <InfoPopover is_in_modal={true} toggler={<span className={cn('ms-1', s.tooltip_trigger)}><InfoIcon/></span>}>
          Currently, used only in family details view to display claims and descriptions
        </InfoPopover>
      </div>
      <BaseDropdown
        label={LANGUAGE_CODE_TO_OPTION[language_preference].name}
        buttonClassName={s.language_btn}
        menuClassName={s.language_menu}
        className='mt-2'
      >
        {LANGUAGE_OPTIONS.map(language => {
          const { id, name } = language
          return (
            <DropdownItem
              key={id}
              onClick={() => on_change_language_setting(id)}

              active={language_preference === id}
            >
              {name}
            </DropdownItem>
          )
        })}
      </BaseDropdown>
    </>
  )
}

const UserSettingsDisplay = (
  {
    user,
    user_settings,
    user_chart_sets,
    default_chart_selection,
    change_default_chart_selection_handler,
    change_default_utt_version_handler,
    change_starting_page_handler,
    change_region_grouping_handler,
    change_thumbnails_handler,
    change_should_show_ungrouped_families_handler,
    change_should_include_unrelated_techs_handler,
    toggle_notify_before_report_expiry_handler,
    change_language_preference_handler,
    change_save_knn_searches_handler,
    reset_settings_handler,
  }) => {

  const [is_chart_set_menu_open, set_is_chart_set_menu_open] = useState(false)
  const [preferred_chart_selection, set_preferred_chart_selection] = useState(null)
  const [is_confirm_reset, set_is_confirm_reset] = useState(false)

  const region_grouping = get_report_region_grouping(user_settings)
  const charts_in_thumbnails = has_charts_in_thumbnails(user_settings)
  const should_show_ungrouped_families = get_show_ungrouped_families(user_settings)
  const default_chart_selection_for_display = preferred_chart_selection || default_chart_selection
  const starting_page = get_starting_page(user_settings)
  const include_unrelated_techs = should_include_unrelated_technologies(user_settings)
  const default_utt_version = get_default_utt_version(user_settings)
  const notify_before_report_expiry = should_notify_before_report_expiry(user_settings)
  const language_preference = get_language_preference(user_settings)
  const save_knn_searches = get_save_knn_searches(user_settings)

  function toggle_chart_set_menu() {
    set_is_chart_set_menu_open(!is_chart_set_menu_open)
  }

  function on_select_user_chart_set(chart_set) {
    const value = get_default_chart_selection_value(chart_set)
    on_change_default_chart_selection(value)
  }

  function on_change_default_chart_selection(value) {
    change_default_chart_selection_handler(value)
    set_preferred_chart_selection(value)
    set_is_chart_set_menu_open(false)
  }

  function on_confirm_reset_settings() {
    set_preferred_chart_selection(null)
    reset_settings_handler()
    set_is_confirm_reset(false)
  }

  const is_custom_set_selected_as_default = default_chart_selection_for_display && default_chart_selection_for_display.startsWith(CUSTOM_SET)
  const extracted_custom_set_name = is_custom_set_selected_as_default && default_chart_selection_for_display.indexOf(':') > -1 ?
    default_chart_selection_for_display.substr(default_chart_selection_for_display.indexOf(':')+1) :
    null

  const is_tech_discovery_context = is_tech_discovery()

  if (is_tech_discovery_context) {
    return (
      <div>
        <LanguageSetting
          language_preference={language_preference}
          on_change_language_setting={change_language_preference_handler}
        />

        <SimilarFamiliesSearchHistorySetting
          similar_families_search_setting={save_knn_searches}
          on_change_similar_families_search_setting={change_save_knn_searches_handler}
        />
      </div>
    )
  }

  const user_can_build_reports = !is_view_only_user(user)
  const user_has_similar_families = is_creator(user)

  return (
    <div>
      <div className='d-sm-flex'>
        <div className='me-sm-5'>
          <h4>Default settings for new reports</h4>

          <Label className='mt-3'>Open new reports with:</Label>

          <div className='d-flex'>
            <RadiobuttonWithLabel
              is_checked={is_custom_set_selected_as_default}
              on_click={() => set_preferred_chart_selection(CUSTOM_SET)}
              label={
                (
                  <span>
                    Saved selection of charts
                    {is_custom_set_selected_as_default && extracted_custom_set_name &&
                      <span>: <span className={'text-endbold'}>{extracted_custom_set_name}</span></span>
                    }
                  </span>
                )
              }
              className='my-auto'
            />

            <Dropdown isOpen={is_chart_set_menu_open} toggle={toggle_chart_set_menu} className='ms-2 my-auto'>

              <DropdownToggle tag='div'>
                <TextLink title='Choose from saved chart selections' disable={!is_custom_set_selected_as_default}>
                  <span>{is_custom_set_selected_as_default && extracted_custom_set_name ? 'Change' : 'Choose'}</span>
                </TextLink>
              </DropdownToggle>

              <DropdownMenu  className={cn('p-2',s.chart_sets_menu)}>
                <UserChartSetsList
                  user_chart_sets={user_chart_sets}
                  on_select={on_select_user_chart_set}
                />
              </DropdownMenu>
            </Dropdown>
          </div>

          <div className='d-flex'>
            <RadiobuttonWithLabel
              is_checked={default_chart_selection_for_display === CIPHER_DEFAULT}
              on_click={() => on_change_default_chart_selection(CIPHER_DEFAULT)}
              label='Automatic selection of charts'
            />
            <Tooltip toggler={<span className={cn('ms-1 my-auto', s.tooltip_trigger)}><InfoIcon /></span>} is_in_modal={true} placement='left'>
              Classification will choose a set of charts for each report you create based on the type report and the dimensions of the data.
            </Tooltip>
          </div>
          <RadiobuttonWithLabel
            is_checked={default_chart_selection_for_display == null}
            on_click={() => on_change_default_chart_selection(null)}
            label='No charts selected'
          />

          <Label className='mt-3'>When reports first load, go to:</Label>
          <RadiobuttonWithLabel
            is_checked={!starting_page}
            on_click={() => change_starting_page_handler(null)}
            label='Overview page'
          />
          <RadiobuttonWithLabel
            is_checked={starting_page === SELECTED_SUBPATH}
            on_click={() => change_starting_page_handler(SELECTED_SUBPATH)}
            label='Selected charts page'
          />

          <Label className='mt-3'>Region charts use:</Label>
          {REPORT_REGION_GROUPING_OPTIONS.map((item, i) => (
            <RadiobuttonWithLabel
              key={i}
              is_checked={region_grouping === item.id}
              on_click={() => change_region_grouping_handler(item.id)}
              label={item.label}
            />
          ))}

          {!BROWSER_IS_IE_11 &&
            <div className='d-none'>
              <Label className='mt-3'>Display thumbnails as:</Label>
              <RadiobuttonWithLabel
                is_checked={charts_in_thumbnails === true}
                on_click={() => change_thumbnails_handler(true)}
                label='Report data'
              />
              <RadiobuttonWithLabel
                is_checked={charts_in_thumbnails === false}
                on_click={() => change_thumbnails_handler(false)}
                label='Icons only'
              />
            </div>
          }

          <Label className='mt-3'>Ungrouped families are:</Label>
          <RadiobuttonWithLabel
            is_checked={should_show_ungrouped_families === false}
            on_click={() => change_should_show_ungrouped_families_handler(false)}
            label='Hidden'
          />
          <RadiobuttonWithLabel
            is_checked={should_show_ungrouped_families === true}
            on_click={() => change_should_show_ungrouped_families_handler(true)}
            label='Included'
          />
        </div>

        <div className='mt-2 mt-sm-0'>
          {user_can_build_reports &&
            <>
              <h4>Default settings for building reports</h4>

              <Label className='mt-3'>Unrelated technologies should be:</Label>
              <RadiobuttonWithLabel
                is_checked={include_unrelated_techs === true}
                on_click={() => change_should_include_unrelated_techs_handler(true)}
                label='Included'
              />
              <RadiobuttonWithLabel
                is_checked={include_unrelated_techs === false}
                on_click={() => change_should_include_unrelated_techs_handler(false)}
                label='Excluded'
              />

              {has_utt(user) && UTT_VERSIONS.length > 1 &&
                <>
                  <Label className='mt-3'>UTT (Universal Technology Taxonomy) version:</Label>
                  {UTT_VERSIONS.map((utt_version, i) => {
                    const { id, label, check_if_available } = utt_version

                    const is_available = check_if_available ? check_if_available({user}) : true

                    if (!is_available) return null

                    return (
                      <RadiobuttonWithLabel
                        key={i}
                        is_checked={default_utt_version === id}
                        on_click={() => change_default_utt_version_handler(id)}
                        label={label}
                      />
                    )
                  })}
                </>
              }
            </>
          }

          <h4 className='mt-3'>Other Classification settings</h4>

          <Label className='mt-3'>Expiring report notifications:</Label>
          <RadiobuttonWithLabel
            is_checked={notify_before_report_expiry === true}
            on_click={() => toggle_notify_before_report_expiry_handler(true)}
            label='Notification by email'
          />
          <RadiobuttonWithLabel
            is_checked={notify_before_report_expiry === false}
            on_click={() => toggle_notify_before_report_expiry_handler(false)}
            label='No notification'
          />

          <LanguageSetting
            language_preference={language_preference}
            on_change_language_setting={change_language_preference_handler}
          />

          {user_has_similar_families &&
            <SimilarFamiliesSearchHistorySetting
              similar_families_search_setting={save_knn_searches}
              on_change_similar_families_search_setting={change_save_knn_searches_handler}
            />
          }
        </div>

      </div>

      {reset_settings_handler && <div className='mt-2'><TextLink disable={is_confirm_reset} onClick={() => set_is_confirm_reset(true)}>Reset all settings to default values</TextLink></div>}
      {is_confirm_reset &&
        <div className='d-flex mt-1'>
          <span>Are you sure you want to reset the settings?</span>
          <PrimaryButton size='sm' className='ms-2' onClick={on_confirm_reset_settings}>Confirm</PrimaryButton>
          <TertiaryButton size='sm' onClick={() => set_is_confirm_reset(false)} className='ms-2'>Cancel</TertiaryButton>
        </div>
      }
    </div>
  )
}

export default withUser(UserSettingsDisplay)