import React, { useEffect, useRef } from 'react'
import _ from 'underscore'
import cn from 'classnames'

import { POSITIVE, NEGATIVE, IGNORE, TEST_POSITIVE, TEST_NEGATIVE } from '../constants/labels.js'
import { IS_PENDING, IS_ERROR } from '../constants/family_state.js'
import { HIGHLIGHT_PHRASE_CONTEXT_MENU_CLASS } from './HighlightPhrasesContextMenu.js'

import { format_score } from '../utils/training_set_utils.js'
import {
  get_priority_year,
  get_owners_string,
  get_group_cpc_to_base_cpcs,
  get_top_score
} from '../utils/patent_field_utils.js'
import { get_is_show_scope } from '../utils/scope_modal_utils.js'
import { create_cipher_family_id_from_family_id } from '../../../utils/family_view_utils.js'
import {
  is_element_vertically_onscreen,
  SCROLL_INTO_VIEW_BEHAVIOUR_INSTANT,
  scroll_to_centre,
} from '../../../utils/scroll_utils.js'
import {
  ChevronLeftIcon,
  ChevronRightIcon
} from '../../widgets/IconSet.js'
import { Highlighter } from '../../widgets/Highlighter.js'
import ClassifierLabelControl from './ClassifierLabelControl.js'
import ClassifierStatusMessage from './ClassifierStatusMessage.js'
import SuggestionsStrategyName from './SuggestionsStrategyName.js'
import CipherFamilyLink from '../../widgets/CipherFamilyLink'
import CpcWithHover from '../../family_view/CpcWithHover.js'
import AddFamilyToInput from '../../patent_family_list/AddFamilyToInput.js'
import FamilyActions from '../../tech_explorer/FamilyActions.js'
import InputStatusMarker from '../../tech_explorer/InputStatusMarker.js'
import ClassifierLabelMarker from './ClassifierLabelMarker.js'
import { useClick } from '../../../hooks/general_hooks.js'
import ClassifierScoreDisplay from './ClassifierScoreDisplay.js'

import cs from '../../cipher_styles.module.scss'
import s from './PatentCard.module.scss'

/**
 * Renders a fake "card" with a prev/next page control.
 * @param show_prev If true, shows "prev" page control. Otherwise, shows "next" page control.
 * @param num_pages
 * @param current_page
 * @param on_change_current_page
 */
export const PageControlCard = (
  {
    show_prev,
    num_pages,
    current_page,
    on_change_current_page
  }) => {

  return (
    <div
      className={cn('d-flex', 'align-items-center', s.container, s.container__page_control)}
      onClick={() => {
        on_change_current_page(show_prev ? (current_page - 1) : (current_page + 1))
      }}
    >
      <div
        className={cn(s.page_control_body, 'd-flex')}
      >

        {!show_prev &&

          <>
            <span className={cn('my-auto', s.page_control_text)}>
              next page (<span>{(current_page + 2)}</span> of <span>{num_pages}</span>)
            </span>
            <ChevronRightIcon className={cn(s.page_control_overlay_icon)}/>
          </>
        }

        {show_prev &&
          <>
            <ChevronLeftIcon className={cn(s.page_control_overlay_icon)}/>
            <span className={cn('my-auto', s.page_control_text)}>
              previous page (<span>{current_page}</span> of <span>{num_pages}</span>)
            </span>
          </>
        }

      </div>
    </div>
  )
}

const PatentCard = (
  {
    subidx, show_classifier_controls, patent, is_selected, no_highlighting, search_phrases, search_colours, highlight_prefix_only, source, set_label,
    show_suggestion_strategy,
    eval_training_set_id, eval_classifier_data,
    on_show_details,

    similar_search_input_family_ids, similar_search_input, on_include_family_id_in_similar_search_input,
    knn_family_ids, knn_blocklist, knn_bookmarked,
    on_add_to_knn_family_ids, on_add_to_knn_blocklist, on_add_to_knn_bookmarked,

    wrapper,
    className
  }
) => {

  const card_ref = useRef()

  useEffect(() => {

    const input_focused = document.activeElement && _.contains(['INPUT', 'TEXTAREA'], document.activeElement.tagName) && document.hasFocus()

    if (get_is_show_scope() || input_focused || (document.getElementsByClassName(HIGHLIGHT_PHRASE_CONTEXT_MENU_CLASS).length > 0)) {
      return
    }

    const {offsetTop = 0, offsetHeight = 0} = wrapper || {}

    if (is_selected && !is_element_vertically_onscreen(card_ref.current, offsetTop, offsetTop + offsetHeight)) {
      scroll_to_centre(card_ref, SCROLL_INTO_VIEW_BEHAVIOUR_INSTANT)
    }
  }, [is_selected, card_ref, wrapper])

  function on_family_id_click(subidx, cipher_family_id) {
    on_show_details(subidx, cipher_family_id)
  }

  function on_card_click(subidx, cipher_family_id) {
    if (document.getSelection().type === 'Range') return //text is selected

    on_family_id_click(subidx, cipher_family_id)
  }

  const {id, title, abstract, owners, cpcCodes, priorityDate, user_class, strategy_name, strategy_details, similarity_score } = patent

  const patfam_id = id || patent.patFamId
  const cipher_family_id = create_cipher_family_id_from_family_id(patfam_id)

  const priority_year = get_priority_year(priorityDate)
  const owners_string = get_owners_string(owners)

  const group_cpc_to_base_cpcs = cpcCodes ? get_group_cpc_to_base_cpcs(cpcCodes) : {}
  const group_cpcs = Object.keys(group_cpc_to_base_cpcs)

  const is_saving = patent[IS_PENDING]
  const is_error = patent[IS_ERROR]

  const is_positive      = user_class === POSITIVE
  const is_negative      = user_class === NEGATIVE
  const is_test_positive = user_class === TEST_POSITIVE
  const is_test_negative = user_class === TEST_NEGATIVE
  const is_ignore        = user_class === IGNORE

  const highlighter_props = {
    no_highlighting,
    highlight_prefix_only,
    search_words: search_phrases,
    search_colours: search_colours
  }
  const score = get_top_score(patent)
  const has_any_score_to_display = (score != null) || (similarity_score != null)

  const show_knn_actions = (on_add_to_knn_family_ids || on_add_to_knn_blocklist || on_add_to_knn_bookmarked)

  const on_click = useClick({
    on_single_click: () => on_card_click(subidx, cipher_family_id),
    on_multiple_click: () => {}
  })

  return (
    <div
      className={cn(
        s.container,
        {
          [s.__selected]: is_selected
        },
        className
      )}
      ref={card_ref}
    >
      <div className={cn(
        cs.cursor_pointer,
        s.clickable_container,
        {[s.clickable_container__selected]: is_selected}
      )} onClick={on_click}>
        <div className={s.status_marker}>

          {show_knn_actions &&
            <InputStatusMarker
              family_id={patfam_id}
              positives={knn_family_ids}
              negatives={knn_blocklist}
              bookmarked={knn_bookmarked}

              display="card"
              className={s.knn_status}
            />
          }

          <ClassifierLabelMarker
            is_positive={is_positive}
            is_negative={is_negative}
            is_test_positive={is_test_positive}
            is_test_negative={is_test_negative}
            is_ignore={is_ignore}

            className={cn('w-100 h-100', s.training_status)}
          />

        </div>

        {show_suggestion_strategy &&
          <div className={s.extra_header_inner_container}>
            <SuggestionsStrategyName
              strategy_id={strategy_name}
              strategy_details={strategy_details}
            />
          </div>
        }

        <div className={s.body_container_inner}>
          <div className={cn(s.title)}>
            <Highlighter
              {...highlighter_props}
              text_to_highlight={title}
            />
          </div>
          <div className={cn(s.abstract)}>
            {!abstract &&
              <span>(no abstract available)</span>
            }
            <Highlighter
              {...highlighter_props}
              text_to_highlight={abstract}
            />
          </div>
          <div className={cn(s.body_bottom_row, 'd-flex')}>
          <span>
            <Highlighter
              {...highlighter_props}
              text_to_highlight={priority_year}
            />
          </span>
            <span className={cn(s.owners, 'ms-2')}>
            <Highlighter
              {...highlighter_props}
              text_to_highlight={owners_string}
            />
          </span>
            <span className={cn(s.cpc, 'ms-auto')}>
            {group_cpcs.map((group_cpc_code, i) => {
              const base_cpc_codes = group_cpc_to_base_cpcs[group_cpc_code]

              return (
                <CpcWithHover
                  key={i}
                  highlighter_props={highlighter_props}
                  className={cn('ms-1')}
                  label={group_cpc_code}
                  cpc_codes={base_cpc_codes}
                />
              )
            })}
          </span>
          </div>
        </div>
      </div>

      <div className={cn('align-items-center', s.footer)}>
        <div className='position-relative h-100'>
          <div className={cn('d-flex justify-content-between h-100', s.footer__inner)}>

            <div className='d-flex h-100 py-1'>

              {on_include_family_id_in_similar_search_input &&
                <div className={cn('pe-1 me-1 my-auto', s.border_right)}>
                  <AddFamilyToInput
                    family_id={patfam_id}
                    cipher_family_id={cipher_family_id}
                    family_ids={similar_search_input_family_ids}
                    similar_search_input={similar_search_input}
                    on_click={on_include_family_id_in_similar_search_input}
                  />
                </div>
              }

              <CipherFamilyLink
                family_id={cipher_family_id}
                on_family_id_click={() => on_family_id_click(subidx, cipher_family_id)}
                display_text_as_link={true}
                display_link_icon={true}
                show_similar_families_search={true}

                eval_training_set_id={eval_training_set_id}
                eval_classifier_data={eval_classifier_data}
              />

              {((score != null) || (show_knn_actions && (similarity_score != null)))  &&
                <div
                  className={cn(
                    'mx-2',
                    s.score_label,
                    {[s.border_left]: has_any_score_to_display},
                    has_any_score_to_display ? 'ps-1 my-auto' : null
                  )}
                >
                  {(score != null) &&
                    <ClassifierScoreDisplay
                      score={score}
                      className='p-1'
                    />
                  }
                  {(similarity_score != null) && show_knn_actions &&
                    <span title='Similarity score'>
                      {format_score(similarity_score)}
                    </span>
                  }
                </div>
              }
            </div>

            {show_knn_actions &&
              <FamilyActions
                family_id={patfam_id}
                family_ids={knn_family_ids}
                blocklist={knn_blocklist}
                bookmarked={knn_bookmarked}

                on_add_to_family_ids={on_add_to_knn_family_ids}
                on_add_to_blocklist={on_add_to_knn_blocklist}
                on_add_to_bookmarked={on_add_to_knn_bookmarked}
              />
            }

            {show_classifier_controls &&
              <div className={cn('d-flex align-items-center', s.labelling_controls_container)}>
                <ClassifierStatusMessage
                  is_saving={is_saving}
                  is_error={is_error}
                />
                <ClassifierLabelControl
                  className={'ms-1 py-1'}
                  is_saving={is_saving}
                  set_label={set_label.bind(null, source, patent)}
                  selected_label={user_class}
                />
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default PatentCard